/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Container from '../__Container';
import { graphql } from 'gatsby';

import Banner from '../__Banner';
import Pagination from '../__Pagination';
import Table from './_table';
import BannerImg from 'imgs/1d1d/pc/banner.hire.png';
import HireBgImg from 'imgs/1d1d/pc/hire.bg.png';

const Wrapper = styled.div`
  background-color: rgba(254, 251, 244, 1);
  background-image: ${HireBgImg};
  display: flex;
  justify-content: center;
  align-items: center;
  .hireBox {
    margin: 0.3rem;
    background: rgb(255, 255, 255);
    box-shadow: 0px 10px 16px 2px rgba(83, 83, 83, 0.17);
    width: 70.7rem;
  }
  .positions {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
export default function Hire({ data }) {
  const pageSize = 10;
  const Data = data.allMongodbSsdmDdpositions.edges.map((item) => item.node);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchCate, setSearchCate] = useState('');
  const [cates, setCates] = useState([]);
  const [searchDepart, setSearchDepart] = useState('');
  const [departs, setDeparts] = useState([]);
  const [searchLocation, setSearchLocation] = useState('');
  const [locations, setLocations] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const [items, setItems] = useState(Data.slice((currPage - 1) * pageSize, currPage * pageSize));

  const handleSearch = () => {
    let tmp = Data;
    if (searchCate) {
      tmp = tmp.filter((d) => d.cate == searchCate);
    }
    if (searchDepart) {
      tmp = tmp.filter((d) => d.depart == searchDepart);
    }
    if (searchLocation) {
      tmp = tmp.filter((d) => d.location == searchLocation);
    }
    if (searchKeyword) {
      tmp = tmp.filter((d) => {
        return (
          d.title.includes(searchKeyword) ||
          d.cate.includes(searchKeyword) ||
          d.location.includes(searchKeyword)
        );
      });
    }
    setItems(tmp);
  };

  const handleUpdatePage = (page) => {
    setCurrPage(page);
  };
  const handleSearchChange = (evt) => {
    console.log('serach val', evt.target.dataset.key);
    const { value } = evt.target;
    const { key } = evt.target.dataset;
    if (key) {
      const funName = 'setSearch' + key.charAt(0).toUpperCase() + key.slice(1);
      console.log(value);
      eval(`${funName}("${value}")`);
    }
  };
  useEffect(() => {
    const cs = Data.map((item) => {
      return item.cate;
    });
    const ds = Data.map((item) => {
      return item.depart;
    });
    const ls = Data.map((item) => {
      return item.location;
    });
    setCates(Array.from(new Set(cs)));
    setDeparts(Array.from(new Set(ds)));
    setLocations(Array.from(new Set(ls)));
  }, []);
  useEffect(() => {
    setItems(Data);
  }, [currPage]);
  console.log('data hire', Data);
  return (
    <Container>
      <Banner h1="社会招聘" h2s={['experiencd hire']} banner={BannerImg} />
      <Wrapper>
        <div className="hireBox">
          <div className="positions">
            <Table data={items.slice((currPage - 1) * pageSize, currPage * pageSize)} />
            <Pagination
              updatePage={handleUpdatePage}
              currPage={currPage}
              pageSize={pageSize}
              total={items.length}
            />
          </div>
        </div>
      </Wrapper>
    </Container>
  );
}
export const query = graphql`
  query {
    allMongodbSsdmDdpositions {
      totalCount
      edges {
        node {
          title
          id
          updateTime(formatString: "YYYY-MM-DD hh:mm:ss")
          cate
          location
          depart
          link
        }
      }
    }
  }
`;
