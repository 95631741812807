import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  .box {
    cursor: pointer;
    font-size: 0.22rem;
    display: inline-block;
    line-height: 0.55rem;
    text-align: center;
    width: 0.55rem;
    height: 0.55rem;
    color: #636363;
    border: 1px solid #ccc;
    margin-right: 0.4rem;
    margin: 1rem auto;
    margin-right: 0.4rem;

    &.curr {
      color: #fff;
      background: rgb(255, 124, 52);
      border: none;
    }
    &.disable {
      border-color: #ccc;
      color: #ccc;
    }
  }
  &.circle .box {
    border-radius: 50%;
  }
`;
export default function Pagination({
  updatePage,
  pageSize = 10,
  currPage = 1,
  total = 80,
  isCircle = false
}) {
  const pages = Array.from({ length: Math.ceil(total / pageSize) }, (v, k) => k + 1);
  const hasNext = currPage !== pages.length;
  const hasPrev = currPage > 1;
  return (
    !!total && (
      <Wrapper className={isCircle && 'circle'}>
        <div
          onClick={hasPrev ? updatePage.bind(null, currPage - 1) : null}
          className={`box ${!hasPrev && 'disable'}`}
        >
          &lt;
        </div>
        {pages.map(page => {
          return (
            <div
              onClick={updatePage.bind(null, page)}
              key={page}
              className={`box ${page == currPage ? 'curr' : ''}`}
            >
              {page}
            </div>
          );
        })}
        <div
          onClick={hasNext ? updatePage.bind(null, currPage + 1) : null}
          className={`box ${!hasNext && 'disable'}`}
        >
          &gt;
        </div>
      </Wrapper>
    )
  );
}
